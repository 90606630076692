module.exports = [
  {
    path: '/',
    name: 'home',
    prerender: true,
  },
  {
    path: '/skills',
    name: 'skills',
    prerender: true
  },
  {
    path: '/projects',
    name: 'projects',
    prerender: true
  },
  {
    path: '/contact',
    name: 'contact',
    prerender: true
  },
  {
    path: '/reads',
    name: 'reads',
    prerender: true
  }
];
